<template>
    <v-container grid-list text-center>
        <v-card dark color="error" v-if="modals == null">
            <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                    <v-card-title class="headline">Buscando veículo...</v-card-title>
                </div>
                <v-avatar class="ma-3" size="50" tile>
                    <v-progress-circular size="50" width="10" color="white" indeterminate></v-progress-circular>
                </v-avatar>
            </div>
        </v-card>
        <v-card dark color="error" v-else-if="modals.length == 0">
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>Nenhuma veículo disponível para uso.</v-card-title>
            </div>
        </v-card>
        <v-layout wrap v-else-if="itemToChange.ModalOut == null">
            <v-flex xs12 v-for="modal in modalsWaitFinish" :key="modal">
                <v-alert icon="electric_moped" text outlined type="error" v-if="!modal.odometerOut">
                    <strong>Scooter eletrica: {{ modal.modal.plate }} {{ modal.modal.serialNumber }}</strong>
                    <v-btn @click="startChange(modal)" :disabled="!valid" color="success" class="mt-4" style="width: 100%">
                        <v-icon left> published_with_changes </v-icon> Confirma a troca dessa moto?
                    </v-btn>
                </v-alert>
            </v-flex>
            <v-flex xs12 v-if="modalsWaitFinish.length == 0">
                <v-alert icon="electric_moped" text outlined type="error">
                    <strong>Nenhuma moto para ser trocada</strong>
                </v-alert>
            </v-flex>
        </v-layout>

        <v-form v-else v-model="valid" ref="form" lazy-validation @submit.prevent="makeCheckin">
            <v-alert outlined type="warning" icon="stop" text>
                <div class="text-h6">Devolução</div>

                <v-flex xs12 class="ma-2">
                    <strong> Scooter: {{ itemToChange.ModalOut.modal.plate || itemToChange.ModalOut.modal.serialNumber }} </strong>
                </v-flex>
                <v-flex xs12 class="ma-2">
                    <v-text-field v-model="itemToChange.ModalOut.odometerOut" :label="$t('Digite o valor do km atual do painel da moto que está devolvendo')" type="number"
                        suffix="km" :rules="[rules.required, minOdometerOut(itemToChange.ModalOut.odometerIn), rules.odometer]">
                    </v-text-field>
                </v-flex>
            </v-alert>
            <v-alert outlined type="success" icon="play_arrow" text>
                <div class="text-h6">Seleção de nova</div>
                <v-layout wrap>
                    <v-flex xs12>
                        <v-autocomplete v-model="itemToChange.ModalIn.id" :items="modals" :filter="customFilterMotorcyle" item-value="id" :label="$t('Scooter')"
                            :rules="[rules.required]">
                            <template v-slot:item="data">
                                <div v-if="data.item.plate">Placa: {{ data.item.plate }}</div>
                                <div v-if="data.item.serialNumber">Número de Série: {{ data.item.serialNumber }}</div>
                            </template>

                            <template v-slot:selection="data">
                                <div v-if="data.item.plate">Placa: {{ data.item.plate }}</div>
                                <div v-if="data.item.serialNumber">Número de Série: {{ data.item.serialNumber }}</div>
                            </template>
                        </v-autocomplete>
                    </v-flex>

                    <v-flex xs12 class="ma-2">
                        <v-text-field v-model="itemToChange.ModalIn.odometerIn" :label="$t('Digite o valor do km atual do painel da moto pegando')" type="number" suffix="km"
                            :rules="[rules.required, rules.odometer]" />
                    </v-flex>
                </v-layout>
            </v-alert>

            <v-layout wrap>
                <v-flex xs6 class="pr-1">
                    <v-btn @click="makeChange" :disabled="!valid" color="green white--text" style="width: 100%">
                        <v-icon left> published_with_changes </v-icon> Finalizar troca
                    </v-btn>
                </v-flex>
                <v-flex xs6 class="pl-1">
                    <v-btn @click="$emit('onClose')" color="red white--text" style="width: 100%"> <v-icon left> cancel </v-icon> Cancelar </v-btn>
                </v-flex>
            </v-layout>
        </v-form>
    </v-container>
</template>

<script>
import rules from "@/helpers/rules";
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            valid: true,
            rules,
            modals: null,
            itemToChange: {
                ModalOut: null,
                ModalIn: { id: null, odometerIn: null },
            },
            minOdometerOut: (odometerIn) => (value) => value >= odometerIn || this.$t("Odometro de saída tem que ser maior que a entrada."),
        };
    },

    computed: {
        modalsWaitFinish() {
            return this.item.modals.filter((e) => e.odometerOut == null);
        },
    },

    methods: {
        startChange(modal) {
            this.itemToChange.ModalOut = modal;
        },
        customFilterMotorcyle(item, queryText) {
            const searchText = queryText.toLowerCase();
            return (item.plate && item.plate.toLowerCase().indexOf(searchText) > -1) || (item.serialNumber && item.serialNumber.toLowerCase().indexOf(searchText) > -1);
        },
        makeChange() {
            if (this.$refs.form.validate()) {
                this.$http
                    .patch(`checkin/${this.item.id}/changeModal`, {
                        data: this.itemToChange,
                    })
                    .then(() => {
                        this.$emit("onClose");
                    })
                    .catch((error) => this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao fazer troca de bateria.")));
            }
        },
        getModals() {
            this.$http
                .get(`modals/byStore/${this.item.store.id}`)
                .then((result) => {
                    if (result) {
                        this.modals = result;
                    }
                })
                .catch(() => {
                    this.modals = null;
                });
        },
    },

    mounted() {
        this.getModals();
    },
};
</script>
